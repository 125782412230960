const __request = require(`../default/__request`);

// 订单余额
const recordCount = (data) => {
let pParameter = {
    method: 'post',
    urlSuffix: '/wallet/account/queryAccountDetail',
    data:data
  }

  return __request(pParameter)
}
export default recordCount
