const __request = require(`../__request/__request_contentType_json`);

// 支付参数
export default (data) => {
let pParameter = {
    method: 'post',
    urlSuffix: '/payment/params',
    data:data
  }

  return __request(pParameter)
}

