const __request = require(`./__request/__request_contentType_form`);

// 国际机票订单查询
const recordCount = (data) => {
let pParameter = {
    method: 'post',
    urlSuffix: '/flight/international/buyer/manage/order/detail',
    data:data
  }

  return __request(pParameter)
}
export default recordCount
