const __request = require(`./__request/__request_contentType_form`);

// 国内-机票支付
// 接口文档地址：http://183.58.24.99:9999/swagger-ui.html?tdsourcetag=s_pcqq_aiomsg#/
const rate = (data) => {
  pParameter = {
    method: 'post',
    urlSuffix: '/flight/domestic/buyer/pay',
    data: data
  }

  return __request(pParameter)
}
module.exports = rate;
