const __request = require(`./__request/flight-admin/__request_contentType_json`);

// 订单详情查询
// 接口文档地址：http://183.58.24.99:9999/swagger-ui.html?tdsourcetag=s_pcqq_aiomsg#/
const detail = (pParameter) => {
  if (!pParameter) pParameter = {};
  const orderNo = pParameter.orderNo || ``
  pParameter = {
    method: 'post',
    // urlSuffix: '/flight/domestic/buyer/manage/order/detail',
    urlSuffix:'/buyer/dom/manage/orderDetail',
    data: {
      orderNo
    }
  }

  return __request(pParameter)
}
module.exports = detail;
