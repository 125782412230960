const detail = require(`@/lib/data-service/flight/flight_domestic_buyer_manage_order_detail`);
import internationalDetail from '@/lib/data-service/flight/flight_international_buyer_manage_order_detail'
import queryAccountDetail from '@/lib/data-service/flight/wallet_account_queryAccountDetail'
import internationalPay from '@/lib/data-service/flight/fligthInternational/flight_international_buyer_pay'
import payChanging from '@/lib/data-service/flight/flight_international_buyer_white_order_payChange'
import findChangeOrder from "@/lib/data-service/flight/fligthInternationalAdmin/findChangeOrderDetail";
import PayMenthod from "@/lib/common-service/getPayModule";

// 支付
const pay = require(`@/lib/data-service/flight/flight_domestic_buyer_pay`);
import payment from "@/lib/data-service/flight/newFligthApi/payment_params";

export default {
    data() {

        return {
            dialogVisible: false,
            payRadio: '',
            loading: true,
            // 订单号
            orderNo: null,
            // 订单信息
            detail: null,
            // 航班信息
            ordersData: null,
            // 乘客数量
            passengerVal: 1,
            btnShow: false,
            // 乘客类型
            psgTypeOption: [
                {
                    value: '1',
                    label: "成人",
                    disabled: false
                },
                {
                    value: '2',
                    label: "儿童",
                    disabled: false
                }
            ],
            // 联系人信息
            contact: {
                contactEmail: "",
                contactName: "",
                remark: "",
                contactPhone: ""
            },
            // 旅客证件
            cardType: [
                { value: '0', name: "身份证" },
                { value: '1', name: "护照" },
                // {value: 2, name: ''},
                // {value: 3, name: '身份证'},
                { value: '4', name: "港澳通行证" },
                { value: '5', name: "台胞证" },
                { value: '6', name: "台湾通行证" },
                { value: '7', name: "回乡证" },
                { value: '8', name: "军官证" },
                { value: '9', name: "其他" }
            ],
            // 是否同意胤之旅规则
            checked: false,
            // 乘客信息
            passenger: [
                {
                    // 出生日期，格式：yyyy-MM-dd
                    birthday: "",
                    // 证件号码
                    cardNo: "",
                    // 证件类型，0-身份证 1-护照 4-港澳通行证 5-台胞证 6-台湾通行证 7-回乡证 8-军官证 9-其他
                    cardType: 0,
                    // 是否保存至常旅客，0-不保存 1-保存
                    isSaveFrequent: 0,
                    // 手机号
                    mobile: "",
                    // 名字
                    psgName: "",
                    // 乘客类型，1-成人 2-儿童
                    psgType: '1',
                    sex: 0
                }
            ],
            // 乘客类型
            psgType: 3,
            // 输入框验证规则
            // 成人数量
            adultNub: 1,
            // 儿童数量
            childNub: 0,
            // 成人价格
            adultPrice: 0,
            // 儿童价格
            childPrice: 0,
            AllPrice: 0,
            PriceText: "",
            rate: 1,
            detailData: '',
            queryAccount: {},
            image_url_airline_blank_map: `https://lyn.oss-cn-shenzhen.aliyuncs.com/temp/airline-blank-map.jpg`,
            AlipayHtml: ''
        };
    },
    components: { PayMenthod },
    methods: {
        // 计算周几
        Getweek(index) {
            let time = this.$moment(index).valueOf();
            let str = [
                "星期一",
                "星期二",
                "星期三",
                "星期四",
                "星期五",
                "星期六",
                "星期日"
            ];
            let week = Number(this.$moment(time).format("E")) - 1;
            return str[week];
        },
        getOrderList() {
            let type = this.$route.query.type
            type ? this.$router.push({ name: 'air-ticket-admin-international-list' }) : this.$router.push({ name: 'air-ticket-admin-purchase-order-list' })
        },
        getPayMoney(val) {
            queryAccountDetail().then(res => {
                this.queryAccount = res
                res.usableAmount > val && res.passwordStatus == 1 ? this.payRadio = 'YPAY' : this.btnShow = true
            })
        },
        // 通过身份证获取生日
        fromIdCard(index) {
            let idCard = this.passenger[index].cardNo;
            let birthday = "";
            if (idCard != null && idCard != "") {
                if (idCard.length == 15) {
                    birthday = "19" + idCard.substr(6, 6);
                } else if (idCard.length == 18) {
                    birthday = idCard.substr(6, 8);
                }
                birthday = birthday.replace(/(.{4})(.{2})/, "$1-$2-");
            }
            this.passenger[index].birthday = birthday;
        },
        airDetail() {
            let type = this.$route.query.type
            type ?
                this.$router.push({
                    name: 'air-ticket-admin-international-list-detail',
                    query: { ordersNo: this.orderNo, type: 'airDetails' }
                }) :
                this.$router.push({ name: 'air-ticket-admin-airDetails', query: { ordersNo: this.orderNo } })
        },
        // 获取信息
        getAirParams(fn) {
            this.loading = true
            this.orderNo = this.$route.query.ordersNo
            let params = {
                orderNo: this.orderNo
            }
            internationalDetail({ orderId: this.orderNo }).then(res => {
                this.loading = false
                this.detailData = res.data
                this.passenger = res.data.passengerList
                this.contact = res.data
                // fn(res.data.payAmount)
                if (this.$route.query.pay) {
                    this.getFillingDetail()
                } else {
                    this.ordersData = res.data.passengerList[0].routeList
                    this.AllPrice = res.data.payAmount ? res.data.payAmount : 0
                }

                this.payInit()
            }, (error) => {
                this.loading = false
            })
        },
        getFillingDetail() {
            let chaOrderId = this.$route.query.chaOrderId
            findChangeOrder({ chaOrderId: chaOrderId }).then(res => {
                this.ordersData = res.data.passengers[0].newRouteList
                this.AllPrice = res.data.realAddMoneyAmount
            })
        },
        // 深克隆
        deepClone(obj) {
            let _obj = JSON.stringify(obj);
            let objClone = JSON.parse(_obj);
            return objClone;
        },
        // 计算成人数量
        compAdultNub(data) {
            return data.psgType === 1;
        },
        // 计算儿童数量
        compChildNub(data) {
            return data.psgType === 2;
        },
        goOrderList() {
            let type = this.$route.query.type
            let path = type ? 'air-ticket-admin-international-list' : 'air-ticket-admin-purchase-order-list'
            this.$router.push({ name: 'pay-success-message', query: { path: path } })
        },
        payInit() {
            let orderInfo
            let pay = this.$route.query.pay ? 22 : 21
            let __this = this
            console.log(this.detailData)
            orderInfo = payment({ type: pay })
            orderInfo.then(res => {
                this.$refs.payComponent.init({
                    situationParameter: {
                        "000": {
                            companyId: res.companyId,
                            clubId: res.clubId,
                            orderNo: __this.detailData.orderNo,
                            businessContent: res.businessContent,
                            subject: "国内机票支付订单",
                            orderAmount: Math.floor(__this.detailData.payAmount),
                            count_down: {
                                enable: false,
                                // pay_remaining_seconds: __this.detailData.remainingPaymentTime
                            }
                        }
                    },
                    //支付成功事件处理方法
                    pay_successful_event_handler() {
                        __this.$router.push({
                            name: "air-ticket-admin-international-list"
                        });
                        __this.$message({ type: "success", message: "支付成功！" });

                    },
                    //支付失败事件处理方法
                    pay_failed_event_handler() {
                        __this.$message({
                            type: "warning",
                            message: "支付失败，请重新支付！"
                        });
                    },
                });
            })
        },
        // 提交表单
        submit() {
            if (!this.payRadio) return this.$message({ type: "warning", message: "请选择支付方式" })
            let type = this.$route.query.type
            let payType = this.$route.query.pay
            let orderId = this.$route.query.chaOrderId
            let _this = this
            let params = {
                orderNo: orderId ? orderId : this.orderNo,
                paymentChannel: this.payRadio,
                paymentType: payType ? payType : type ? 21 : 11,
                totalFee: this.AllPrice,
                paymentPassword: ''
            }

            if (this.payRadio === 'YPAY') {
                this.$prompt('请输入密码', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    inputType: 'password'
                }).then(({ value }) => {
                    if (!value) return
                    params.paymentPassword = value
                    payment()
                }).catch(() => {
                })

            } else {
                payment()
            }

            function payment() {
                _this.loading = true
                pay(params).then(res => {
                    if (res.code === _this.SUCCESS_CODE) {
                        _this.loading = false
                        if (_this.payRadio === 'YPAY') {
                            return _this.goOrderList()
                        }
                        let { href } = _this.$router.resolve({
                            name: "air-pay-page",
                            query: { data: res.htmlStr }
                        })
                        _this.payRadio === 'YEEPAY' ?
                            window.open(res.htmlStr) : _this.payRadio === 'ALIPAY' ?
                                (window.open(href, '_blank')) : ''
                        _this.dialogVisible = true
                    }
                }, (error) => {
                    _this.loading = false
                })
            }
        }
    },
    watch: {
        passenger: {
            handler: function () {
                this.adultNub = this.passenger.filter(this.compAdultNub).length;
                this.childNub = this.passenger.filter(this.compChildNub).length;
            },
            deep: true
        }
    },
    created() {
        this.getAirParams()
    }
};